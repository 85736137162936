html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: initial;
}

body {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-right: 0 !important;
}

#__next {
  flex: 1;
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
